<template>
    <v-form
        v-model="formValid"
        ref="form"
        lazy-validation
    >
        <v-alert
            v-if="!isEmptyObj(location.info.top)"
            class="text-center"
            type="info"
            dense
            outlined
        >

            <template v-if="location.info.top.showInstruction">
                Please update location below if item has moved
                <div class="d-flex justify-center pa-0 ma-0">
                    <v-checkbox
                        v-model="location.noChanges"
                        label="No changes to the location"
                        class="ma-0"
                        hide-details
                    />
                </div>
            </template>
            <template v-else-if="location.info.top.noChanges">
                No changes to current location on floor plan
            </template>
        </v-alert>

        <div style="position: relative">
            <div :class="{'overlay': isLocked }"></div>
            <v-row>
                <v-col>
                    <div class="overline">Building</div>
                    <v-select
                        v-model="review.location.building"
                        item-text="name"
                        item-value="id"
                        ref="selectBuilding"
                        :items="buildingList"
                        :disabled="isApproved || isLocked"
                        @change="loadBuildingFloorPlans(true)"
                        hide-details
                        dense
                        outlined
                        return-object
                    />
                </v-col>
                <template v-if="!location.remote">
                    <v-col v-if="!location.remote">
                        <div class="overline">Floor</div>
                        <template v-if="location.floorList.length">
                            <v-select
                                v-model="review.location.floor"
                                ref="selectFloorList"
                                :items="location.floorList"
                                :menu-props="location.floorListMenuProps"
                                :rules="$rules.required"
                                :disabled="isApproved || isLocked"
                                @change="loadBuildingFloorPlans(false, true)"
                                hide-details
                                dense
                                outlined
                            />
                        </template>
                        <template v-else>
                            <v-text-field
                                v-model="review.location.floor"
                                :rules="$rules.required"
                                :disabled="isApproved || isLocked"
                                hide-details
                                dense
                                outlined
                            />
                        </template>
                    </v-col>
                    <v-col>
                        <div class="overline">Room</div>
                        <v-text-field
                            v-model="review.location.room"
                            :rules="$rules.required"
                            :disabled="isApproved || isLocked"
                            hide-details
                            dense
                            outlined
                        />
                    </v-col>
                </template>
                <template v-else>
                    <v-col cols="12">
                        <div class="overline">Address</div>
                        <v-text-field
                            v-model="review.location.address"
                            :rules="$rules.required"
                            :disabled="isApproved || isLocked"
                            hide-details
                            dense
                            outlined
                            :autofocus="!approverMode"
                        />
                    </v-col>
                    <v-col cols="12">
                        <div class="overline">Apt/Suite/Other</div>
                        <v-text-field
                            v-model="review.location.address2"
                            :disabled="isApproved || isLocked"
                            hide-details
                            dense
                            outlined
                        />
                    </v-col>
                    <v-col cols="4">
                        <div class="overline">City</div>
                        <v-text-field
                            v-model="review.location.city"
                            :rules="$rules.required"
                            :disabled="isApproved || isLocked"
                            hide-details
                            dense
                            outlined
                        />
                    </v-col>
                    <v-col cols="4">
                        <div class="overline">State</div>
                        <v-text-field
                            v-model="review.location.state"
                            :rules="$rules.required"
                            :disabled="isApproved || isLocked"
                            hide-details
                            dense
                            outlined
                        />
                    </v-col>
                    <v-col cols="4">
                        <div class="overline">Zip</div>
                        <v-text-field
                            v-model="review.location.zip_code"
                            :rules="$rules.required"
                            :disabled="isApproved || isLocked"
                            hide-details
                            dense
                            outlined
                        />
                    </v-col>
                </template>
            </v-row>
            <div class="mt-5" v-if="!location.remote">
                <v-expand-transition>
                    
                    <div v-show="!isEmptyObj(location.info.middle)" class="text-center">
                        <v-alert
                            type="info"
                            outlined
                            dense
                        >
                            <template v-if="location.info.middle.notOnThisFloorPlan">
                                {{ item.name }} will be added to {{ review.location.building.name }} {{ review.location.floor }} and
                                <span class="text-decoration-underline font-weight-bold">removed from all other floor plans</span>
                            </template>
                            <template v-else-if="location.info.middle.noAnnotationItem">
                                {{ item.name }} will be added to the {{ review.location.building.name }} {{ review.location.floor }} floor plan
                            </template>
                            <div v-show="location.endpointNeedsMove">
                                <span :class="{'font-weight-bold red--text': location.endpointMoveError}">Please move the circle below to the correct location</span>
                                <div class="caption grey--text">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                                v-bind="attrs"
                                                v-on="on"
                                            ><v-icon small>mdi-map-marker-question</v-icon>Location unknown?</span>
                                        </template>
                                        <span>Return to previous step and select status "Cannot be found"</span>
                                    </v-tooltip>
                                </div>
                            </div>
                        </v-alert>
                    </div>
                </v-expand-transition>
                <div style="height: 500px" :class="{'error-outline shake rounded': location.endpointMoveError}">
                    <div v-if="!Object.keys(location.issues).length" :id="`pspdfkit${item.pk}`" class="fill-height" />
                
                    <div v-else class="d-flex fill-height align-center justify-center">
                        <v-alert
                            color="error"
                            outlined
                            dense
                        >
                            <template v-if="location.issues.selectFloor">
                                Select a floor in {{ review.location.building.name }}
                            </template>
                            <template v-else-if="location.issues.noFloorPlan">
                                Could not find any floor plans for {{ review.location.building.name }}
                            </template>
                            <template v-else-if="location.issues.noLocation">
                                No location for this endpoint. Please fill in the location fields above.
                            </template>
                        </v-alert>
                    </div>
                </div>
            </div>
        </div>
    </v-form>
</template>
<script>
import axios from 'axios';
import PSPDFKit from 'pspdfkit';
import { computed, reactive, ref, toRefs, onMounted, inject } from '@vue/composition-api';

export default {
    name: 'LocationStep',
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        },
        selectAnnotation: {
            type: Boolean,
            default: false
        }
    },

    setup(props, { root, refs }) {
        const store = root.$store;

        let pspdfInstance;

        const choices = {
            status: ['No change', 'Needs to be disposed', 'Cannot be found'],
            tag: ['Tag is legible', 'Tag needs to be replaced', 'No tag'],
            assign: ['No change', 'Assign to Employee', 'Shared (Division)', 'Shared (Department)']
        };

        const { item } = toRefs(props);

        const formValid = ref(true);

        const initLocation = {
            floorList: [],
            floorPlan: {},
            issues: {},
            info: {
                top: {},
                middle: {}
            },
            floorListMenuProps: 'auto',
            endpointNeedsMove: false,
            endpointMoveError: false,
            noChanges: true,
            lock: false,
            remote: false,
        };
        const location = reactive({...initLocation});


        const buildingList = computed(() => store.getters['space/getBuildingList']);
        const review = computed(() => store.getters['inventory/getPendingReview'](props.item.pk));

        const curReview = computed(() => store.getters['inventory/getCurrentReview']);
        
        const approverMode = computed(() => store.getters['inventory/getReviewApproverMode']);

        const isLocked = computed(() => {
            return location.lock && location.noChanges;
        });

        const isApproved = inject('isApproved');


        const resetLocation = () => {
            Object.assign(location, JSON.parse(JSON.stringify(initLocation)));
        };


        const setupAlerts = () => {
            if (!approverMode.value) {
                if (location.endpointOnFloorPlan ) {

                    location.lock = true;
                    location.info.top.showInstruction = true;

                } else if (location.annotationItem) {
                
                    location.info.middle.notOnThisFloorPlan = true;
                    
                } else if (!location.annotationItem) {
                    location.endpointNeedsMove = true;
                    location.info.middle.noAnnotationItem = true;

                }
            } else {

                const reviewLocation = review.value.location;

                if (!reviewLocation.newAnnotation) {
                    // no changes

                    location.lock = true;
                    location.info.top.noChanges = true;
                    
                } else if (!reviewLocation.currentAnnotation && reviewLocation.newAnnotation) {
                    // new location
                    location.info.middle.noAnnotationItem = true;

                } else if (reviewLocation.currentAnnotation.floor_plan?.id !== reviewLocation.newAnnotation.floor_plan?.id) {

                    location.info.middle.notOnThisFloorPlan = true;

                }
            }
        };


        const setupAnnotations = () => {

            if (approverMode.value) {

                if (review.value.location.currentAnnotation) {
                    addAnnotation({
                        bbox: review.value.location.currentAnnotation ? review.value.location.currentAnnotation.bbox : null,
                        readOnly: true
                    });
                }

                if (review.value.location.newAnnotation) {
                    addAnnotation({
                        bbox: review.value.location.newAnnotation.bbox,
                        color: '#FFEB3B',
                        readOnly: approverMode.value && item.value.last_review.approved_at ? true : false
                    });
                }

            } else {
                if (review.value.location.currentAnnotation && !location.requireEndpointMove) {
                    addAnnotation({
                        bbox: review.value.location.currentAnnotation.bbox
                    });

                } else {
                    addAnnotation();
                }
            }
        };


        const loadBuildingFloorPlans = async (bldgChg=false, requireEndpointMove=false) => {

            if (!review.value.location || !review.value.location.building) {
                review.value.location = !curReview.value.location ? item.value.location : curReview.value.location;
            }

            resetLocation();
            
            if (review.value.location.building.name === '') {
                location.issues.noLocation = true;
                return;
            }

            if (review.value.location.building && review.value.location.building.name === 'Remote / Other') {
                location.remote = true;
                refs.selectBuilding.blur();
                return;
            }

            let url = `/space/load_building_floor_plans/${review.value.location.building.id}/?endpoint=${item.value.pk}`;

            if (!bldgChg && review.value.location.floor) url += `&floor=${review.value.location.floor}`;

            const response = await axios.get(url);
            
            if (response.data.error && response.data.error == 'no floor plan') {
                return loadBuildingFloorPlans(true);
            }
            location.floorPlan = response.data.floor_plan;
            location.floorList = response.data.floors;
            location.annotationItem = response.data.annotation_item || null;
            location.endpointOnFloorPlan = response.data.endpoint_on_fp;
            location.requireEndpointMove = requireEndpointMove;

            if (location.requireEndpointMove) location.endpointNeedsMove = true;

            setupAlerts();

            if (location.annotationItem && !approverMode.value) {
                review.value.location.currentAnnotation = location.annotationItem;
            }


            if (bldgChg) {

                unloadPDF();

                if (location.floorList.length === 1 && review.value.location.floor !== location.floorList[0]) {

                    review.value.location.floor = location.floorList[0];
                    return loadBuildingFloorPlans(false);

                } else if (location.floorList.length > 1) {

                    await root.$nextTick();
                    
                    // location.floorListMenuProps = {value: true};
                    refs.selectBuilding.blur();
                    refs.selectFloorList.focus();
                    location.issues.selectFloor = true;

                }

                if (review.value.location.room) review.value.location.room = '';
            }


            if (!location.floorPlan) {
                location.issues.noFloorPlan = true;
            } else {
                await loadPDF();

                setupAnnotations();
            }
        };

        const loadPDF = async () => {

            unloadPDF();

            pspdfInstance = await PSPDFKit.load({
                locale: 'en',
                baseUrl: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PSPDFKIT_BASEURL : process.env.VUE_APP_PSPDFKIT_BASEURL_DEV,
                container: `#pspdfkit${item.value.pk}`,
                toolbarItems: [
                    { type: 'pan' },
                    { type: 'zoom-out' },
                    { type: 'zoom-in' },
                    { type: 'zoom-mode' },
                ],
                styleSheets: [`${root.baseUrl}/static/css/pspdfkit.css`,],
                document: location.floorPlan.url,
                licenseKey: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_PSPDFKIT_LICENSE_KEY : process.env.VUE_APP_PSPDFKIT_LICENSE_KEY_DEV,
            });

            pspdfInstance.addEventListener('annotations.update', async () => {
                const instantJSON = await pspdfInstance.exportInstantJSON();
                location.endpointNeedsMove = location.endpointMoveError = false;

                store.dispatch('inventory/updatePendingReview', {
                    id: item.value.pk,
                    field: 'location',
                    value: {
                        ...review.value.location,
                        newAnnotation: {
                            bbox: instantJSON.annotations[0].bbox,
                            type: item.value.endpoint_type,
                            endpoint: {
                                id: item.value.pk,
                                name: item.value.name,
                                hostname: item.value.name,
                                type: item.value.endpoint_type,
                                jira_key: item.value.jira_key
                            },
                            floor_plan: {
                                id: location.floorPlan.id
                            }
                        }
                    }
                });
            });

            if (item.value.last_review && approverMode.value) {
                // prevent any movement of annotation
                const stopProp = (e) => e.stopImmediatePropagation();

                const cd = pspdfInstance.contentDocument;

                pspdfInstance.addEventListener('annotationSelection.change', (ann) => {
                    
                    if (ann && ann.customData && ann.customData.readOnly) {
                        cd.addEventListener('pointermove', stopProp, { capture: true });
                        cd.addEventListener('mousemove', stopProp, { capture: true });
                        cd.addEventListener('touchmove', stopProp, { capture: true });
                        cd.addEventListener('keydown', stopProp, { capture: true });
                    } else {
                        cd.removeEventListener('pointermove', stopProp, { capture: true });
                        cd.removeEventListener('mousemove', stopProp, { capture: true });
                        cd.removeEventListener('touchmove', stopProp, { capture: true });
                        cd.removeEventListener('keydown', stopProp, { capture: true });
                    }
                });


                pspdfInstance.addEventListener('annotations.focus', (ann) => {

                    if (ann && ann.annotation && ann.annotation.customData && ann.annotation.customData.readOnly) {

                        const el = cd.querySelector('.PSPDFKit-Selection-Outline-Border').parentNode;
                        el.style.display = 'none';

                        cd.querySelector('.PSPDFKit-Selection-Outline-Rect').style.cursor = 'auto';
                    }
                });

                cd.addEventListener('mouseover', async ({ target }) => {
                    const closestAnnotation = target.closest('.PSPDFKit-Annotation');

                    if (closestAnnotation) {
                        const allAnnotations = await pspdfInstance.getAnnotations(0);
                        const annotation = allAnnotations.find(a => a.id === closestAnnotation.dataset.annotationId);

                        if (annotation && annotation.customData && annotation.customData.readOnly) {
                            closestAnnotation.style.cursor = 'auto';
                        }
                    }
                });
            }
        };

        const addAnnotation = async (payload={}) => {
            let bbox = payload.bbox;
            const color = !payload.color ? 'red' : payload.color;
            const readOnly = !!payload.readOnly;

            if (!bbox) {
                const pgInfo = pspdfInstance.pageInfoForIndex(0);
                bbox = [Math.round(pgInfo.width / 2), Math.round(pgInfo.height / 2), 20, 20]; // [left, top, width, height]
            }

            const icon = root.drawCircle('', {color: color});
            let file = await fetch(icon);
            file = await file.blob();

            const imgAttachmentId = await pspdfInstance.createAttachment(file);
            
            const imgAnnotation = new PSPDFKit.Annotations.ImageAnnotation({
                imageAttachmentId: imgAttachmentId,
                pageIndex: 0,
                name: '',
                contentType: 'image/png',
                customData: {
                    readOnly: readOnly
                },
                boundingBox: new PSPDFKit.Geometry.Rect({
                    width: bbox[2],
                    height: bbox[3],
                    top: bbox[1],
                    left: bbox[0]
                })
            });

            await pspdfInstance.create(imgAnnotation);

            // annotationId = annotation[0].id;

            pspdfInstance.addEventListener('annotations.delete', async () => {
                await pspdfInstance.create(imgAnnotation);
            });
            
            /*
            root.$nextTick(() => {
                pspdfInstance.setSelectedAnnotation(annotationId)
            });
            */
           
        };

        onMounted(() => {

            

            // review.value.location = !curReview.value.location ? item.value.location : curReview.value.location;
            //review.value.location = !item.value.last_review ? item.value.location : item.value.last_review.data.location;

            loadBuildingFloorPlans();
        });

        

        const validate = () => {
            if (location.endpointNeedsMove) {
                location.endpointMoveError = true;
                return false;
            }
            return refs.form.validate();
        };

        const unloadPDF = () => {
            PSPDFKit.unload(`#pspdfkit${item.value.pk}`);
        };

        

        return {
            choices,
            buildingList,
            location,
            loadBuildingFloorPlans,
            formValid,
            validate,
            unloadPDF,
            review,
            approverMode,
            isLocked,
            isApproved,
            curReview
        }

    }
}
</script>
<style scoped>
.overlay {
    background-color: rgba(255, 255, 255, 0.4);      
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
</style>