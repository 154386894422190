<template>
    <v-main>
        <v-container fluid style="max-width: 1400px">
            <v-row>
                <v-col>
                    <div class="text-h4 mb-6">IT Inventory Review</div>
                </v-col>
                <v-col v-if="isApprover" class="d-flex align-center justify-end mr-6">
                    <v-switch
                        label="Approver View"
                        :value="isApproverMode"
                        @change="chgApproverMode"
                    />
                </v-col>
            </v-row>

            <div v-if="loading">
                <v-skeleton-loader class="mb-4" type="paragraph" v-for="n in 10" :key="n" />
            </div>
            <div v-else-if="isApproverMode">
                <div class="text-h5 font-weight-light grey--text text--darken-2">
                    <v-icon large left>mdi-clock-check-outline</v-icon>
                    Pending Approval
                </div>
                <v-sheet
                    v-show="isEmptyObj(reviewData)"
                    class="pa-3 text-center text-h6 font-weight-light mt-3"
                    outlined
                    rounded
                >
                    <v-icon large left color="success">mdi-party-popper</v-icon>
                    No reviews to approve!
                </v-sheet>
            </div>
            <div v-else-if="isEmptyObj(reviewData)">
                <v-sheet
                    class="pa-3 text-center text-h6 font-weight-light"
                    outlined
                    rounded
                >
                    No divisions / items to review
                </v-sheet>
            </div>

            <div v-show="!loading" v-for="(v, k, i) in reviewData" class="mt-3" :key="i">
                <div class="text-h5" v-text="k" />

                <v-tabs v-model="tab[k]">
                    <v-tab v-for="(v2, i2) in Object.keys(v)" :key="i2">
                        <v-icon v-text="endpointIcon[v2]" left />
                        {{ pluralize(v2, v[v2].length) }}
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab[k]">
                    <v-tab-item
                        v-for="([k2, v2], i2) in Object.entries(v)"
                        :key="i2"
                    >
                        <v-data-table
                            :headers="headers[k2]"
                            :items="v2"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:item.__str__="{ item }">
                                <EndpointDialog
                                    :endpoint="item"
                                />
                            </template>
                            <template v-slot:item.last_checkin="{ item }">
                                <span v-if="item.last_checkin" :title="item.last_checkin">{{ dateDistance(item.last_checkin) }} ago</span>
                                <span v-else class="grey--text">Never</span>
                            </template>
                            <!--
                            <template v-slot:item.endpoint_status="{ item }">
                                <v-chip
                                    v-text="item.endpoint_status"
                                    :color="endpointStatus[item.endpoint_status]"
                                    small
                                    outlined
                                />
                            </template>
                            -->
                            <template v-slot:item.action="{ item }">
                                <ReviewDialog :item="item" />
                            </template>
                        </v-data-table>
                    </v-tab-item>
                </v-tabs-items>
            </div>

            <div v-show="!loading && lastApprovedReviews.length" class="mt-5">
                <div class="text-h5 font-weight-light grey--text text--darken-2">
                    <v-icon large left>mdi-check-circle-outline</v-icon>
                    Last Approved
                </div>
                <v-data-table
                    :headers="headers['Approved']"
                    :items="lastApprovedReviews"
                    disable-pagination
                    hide-default-footer
                >
                    <template v-slot:item.__str__="{ item }">
                        <EndpointDialog
                            :endpoint="item"
                        />
                        <router-link
                            :to="{name: 'it/inventory/endpoint', params: {endpointId: item.pk}}"
                            v-text="item.__str__"
                        />
                    </template>
                    <template v-slot:item.last_checkin="{ item }">
                        <span v-if="item.last_checkin" :title="item.last_checkin">{{ dateDistance(item.last_checkin) }} ago</span>
                        <span v-else class="grey--text">Never</span>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <ReviewDialog :item="item" />
                    </template>
                </v-data-table>
            </div>
        </v-container>
    </v-main>
</template>
<script>
import { ref, computed, onMounted } from '@vue/composition-api';

import EndpointDialog from '@/components/it/inventory/EndpointDialog';
import ReviewDialog from '@/components/it/inventory/review/ReviewDialog';
import stringHelper from '@/composables/common/string';


export default {
    components: {
        ReviewDialog,
        EndpointDialog
    },
    setup(_, { root }) {
        const store = root.$store;

        const { pluralize } = stringHelper();

        const reviewData = computed(() => store.getters['inventory/getReviews']);
        const lastApprovedReviews = computed(() => store.getters['inventory/getApprovedReviews']);

        const tab = ref({});
        const loading = ref(true);

        const commonHeaders = [{
            text: 'Name',
            value: '__str__',
        }, {
            text: 'Make / Model',
            value: 'spec'
        }, {
            text: 'Last Check-in',
            value: 'last_checkin'
        }, {
            text: 'Location',
            value: 'location_name'
        }, {
            text: 'Primary User',
            value: 'primary_user_name',
        }, {
            text: 'Status',
            value: 'action',
            sortable: false
        }];

        const headers = {
            'Computer': [...commonHeaders],
            'Monitor': [...commonHeaders],
            'Printer / MFP': [...commonHeaders],
            'Thin Client': [...commonHeaders],
            'Approved': [...commonHeaders]
        };

        const isApprover = computed(() => store.getters['user']?.groups?.includes('it-endpoint-review-approvers'));
        const isApproverMode = ref(false);

        const loadReviewData = async () => {
            loading.value = true;
            await store.dispatch('inventory/loadReviews', {approver: isApproverMode.value});
            store.dispatch('inventory/setReviewApproverMode', isApproverMode.value);
            loading.value = false;
        };

        onMounted(() => {
            isApproverMode.value = isApprover.value && !!root.$route.query.approver;
            loadReviewData();
            store.dispatch('space/loadBuildings');
        });

        const chgApproverMode = () => {
            isApproverMode.value = !isApproverMode.value;
            if (isApproverMode.value) {
                root.$router.push({ name: 'it/inventory/review', query: { approver: 1 }});
            } else if (root.$route.query.approver) {
                root.$router.push({ name: 'it/inventory/review' });
            }
            loadReviewData();
        };

        return {
            reviewData,
            tab,
            headers,
            loading,
            isApprover,
            isApproverMode,
            chgApproverMode,
            lastApprovedReviews,
            pluralize
        }
    }
};
</script>