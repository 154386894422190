<template>
    <v-form
        v-model="formValid"
        ref="form"
        lazy-validation
    >
        <v-row>
            <v-col cols="6">
                <div class="overline">Current Status</div>
                <div :class="`${endpointStatus[item.endpoint_status]}--text text-h5`">{{ item.endpoint_status }}</div>
            </v-col>
            <v-col cols="6">
                <div class="overline">New Status</div>
                <v-select
                    v-model="review.status"
                    :items="choices.status"
                    :disabled="isApproved"
                    hide-details
                    dense
                    outlined
                />
            </v-col>
            <template v-if="!['Cannot be found', 'Needs to be disposed'].includes(review.status)">
                <v-col cols="6">
                    <div class="overline">Current Assignment</div>
                    <div class="text-h5">
                        <span v-if="item.primary_user" v-text="item.primary_user.name" />
                        <span v-else>Shared (Division)</span>
                    </div>
                </v-col>
                <v-col cols="6">
                    <div class="overline">New Assignment</div>
                    <v-select
                        v-model="review.assign"
                        :items="choices.assign"
                        :disabled="isApproved"
                        hide-details
                        dense
                        outlined
                    />
                </v-col>
                <v-expand-transition>
                    <v-col cols="6" offset="6" v-if="review.assign === 'Assign to Employee'">
                        <SearchPeople
                            label="Assign to"
                            v-model="review.assignTo"
                            :autofocus="false"
                            :rules="$rules.required"
                            :disabled="isApproved"
                            dense
                            employees-only
                            hide-details
                            outlined
                        />
                    </v-col>
                </v-expand-transition>
                <v-col cols="6">
                    <div class="overline">Tag Legibility</div>
                    <v-select
                        v-model="review.tag"
                        :items="choices.tag"
                        :disabled="isApproved"
                        hide-details
                        dense
                        outlined
                    />
                </v-col>
                <v-col cols="6" v-if="!['Printer / MFP'].includes(item.endpoint_type)">
                    <div class="overline">Peripherals</div>
                    <v-select
                        v-model="review.peripherals"
                        :items="choices.peripherals"
                        :disabled="isApproved"
                        hide-details
                        dense
                        outlined
                    />
                </v-col>
            </template>
            <v-col cols="12">
                <div class="overline">Notes</div>
                <v-textarea
                    v-model="review.notes"
                    :rules="['Cannot be found'].includes(review.status) || review.peripherals.includes('Other') ? $rules.required : []"
                    :disabled="isApproved"
                    hide-details
                    dense
                    outlined
                />
            </v-col>
        </v-row>
    </v-form>
</template>
<script>
import { ref, computed, inject } from '@vue/composition-api';

import SearchPeople from '@/components/population/SearchPeople';

export default {
    name: 'StatusAssignmentStep',
    components: {
        SearchPeople
    },
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    setup(props, { refs, root }) {

        const store = root.$store;
    
        const choices = {
            status: ['No change', 'Needs to be disposed', 'Cannot be found'],
            tag: ['Tag is legible', 'Tag needs to be replaced', 'No tag'],
            assign: ['No change', 'Assign to Employee', 'Shared (Division)', 'Shared (Department)'],
            peripherals: ['All OK', 'Needs new mouse', 'Needs new keyboard', 'Needs new keyboard + mouse', 'Other (specify in notes)']
        };
        
        const formValid = ref(true);
        const review = computed(() => store.getters['inventory/getPendingReview'](props.item.pk));

        const validate = () => refs.form.validate();

        const isApproved = inject('isApproved');

        return {
            choices,
            formValid,
            validate,
            review,
            isApproved,
        }

    }
};
</script>