<template>
    <v-dialog
        v-model="config.showDialog"
        width="900"
        persistent
    >
        <template v-slot:activator="{ on, attrs }" v-if="item">
            <v-btn
                v-bind="attrs"
                v-on="on"
                :color="actionBtn.color || 'warning'"
                @click="setupReview"
                text
                small
            >
                <v-icon left>{{ actionBtn.icon || 'mdi-alert' }}</v-icon>{{ actionBtn.text }}
            </v-btn>
            <div
                v-show="statusTime.time"
                class="ml-2 mt-n1 caption grey--text"
                :title="statusTime.time || false"
                v-text="statusTime.timeStr"
            />
        </template>
        <v-card v-if="review">
            <v-card-title>
                {{ item.name }} Review #{{ reviewNum }}
                <v-chip
                    v-if="config.isNewReview"
                    class="ml-1 pa-2"
                    color="info"
                    x-small
                    label
                    outlined
                >NEW</v-chip>
                <v-spacer />
                <v-select
                    v-if="prevReviews.length > 1 && !config.isNewReview"
                    style="max-width: 200px"
                    :value="config.selectedReview"
                    :items="prevReviews"
                    @change="chgReview"
                    item-value="review_num"
                    outlined
                    hide-details
                    dense
                    return-object
                >
                    <template v-slot:selection="data">
                        <template v-if="data.item.review_num ">
                            Review #{{ data.item.review_num }} 
                        </template>
                        <template v-else>
                            <span class="grey--text">&mdash; All Reviews &mdash;</span>
                        </template>
                    </template>

                    <template v-slot:item="data">
                        <template v-if="data.item.review_num">
                            Review #{{ data.item.review_num }}
                        </template>
                        <template v-else>
                            <span class="grey--text">&mdash; All Reviews &mdash;</span>
                        </template>
                    </template>
                </v-select>    
            </v-card-title>
            <v-card-text>
                <v-alert
                    v-if="curReview.created_at && !config.isNewReview"
                    class="text-center"
                    :type="noticeType"
                    dense
                    text
                >
                    This review was submitted {{ fmtDate(curReview.created_at) }} and
                    <template v-if="curReview.approved_at">
                        approved by IT on {{ fmtDate(curReview.approved_at) }}<br>
                        <template v-if="item.needs_review && item.needs_review.soon">
                            A new review is due on <strong v-text="fmtDate(item.needs_review.date)" /><br>
                        </template>
                        <v-btn
                            v-if="isLatestReview"
                            class="mt-2"
                            :color="noticeType"
                            @click="setupReview($event, true)"
                            outlined
                            small
                        ><v-icon small>mdi-plus</v-icon>Create New Review</v-btn>
                    </template>
                    <template v-else>
                        is pending approval by IT
                    </template>
                </v-alert>
                <v-stepper v-model="curStep">
                    <v-stepper-header>
                        <v-stepper-step
                            v-for="(step, i) in steps"
                            edit-icon="$complete"
                            :key="i"
                            :step="i + 1"
                            :editable="(!item.last_review && curStep > i + 1) || approverMode || config.isNewReview"
                            :complete="curStep > i + 1"
                        >{{ step.name }}</v-stepper-step>
                    </v-stepper-header>
                    <v-stepper-items>
                        <v-stepper-content
                            v-for="(step, i) in steps"
                            :step="i + 1"
                            :key="`${i}-content`"
                        >
                            <component
                                :is="step.component"
                                :ref="step.ref"
                                :item="item"
                            />
                        </v-stepper-content>
                    </v-stepper-items>
                </v-stepper>
            </v-card-text>
            <v-card-actions class="pb-5">
                <div
                    v-if="showAnnotationKey"
                    class="caption grey--text text--darken-3"
                >
                    <template v-if="review.location.currentAnnotation">
                        <v-icon color="red" left>mdi-circle</v-icon>{{ !item.last_review.approved_at ? 'Current' : 'Previous' }} Location
                    </template>
                    <template v-if="newLocation">
                        <v-icon color="#FFEB3B" class="ml-3" left>mdi-circle</v-icon>{{ !item.last_review.approved_at ? 'New' : 'Current' }} Location
                    </template>
                </div>
                <v-spacer />
                <template v-if="!item.last_review || approverMode || config.isNewReview">
                    <v-btn
                        v-if="curStep === steps.length && !approverMode"
                        color="primary"
                        :loading="config.loadingSubmit"
                        :disabled="config.loadingSubmit"
                        @click="submit"
                    >Submit</v-btn>
                    <v-btn
                        v-else-if="curStep === steps.length && approverMode && !item.last_review.approved_at"
                        color="success"
                        :loading="config.loadingSubmit"
                        :disabled="config.loadingSubmit"
                        @click="approve"
                    >Approve</v-btn>
                    <v-btn
                        v-else-if="curStep && curStep !== steps.length"
                        color="primary"
                        @click="nextStep"
                    >Continue</v-btn>
                    <v-btn
                        color="primary"
                        @click="cancelDialog"
                        text
                    >Cancel</v-btn>
                </template>
                <template v-else>
                    <v-btn
                        v-if="curReview.created_at && !config.isNewReview && !curReview.approved_at"
                        color="error"
                        @click="cancelReview"
                        :loading="config.loadingDelete"
                        text
                    >Cancel Review</v-btn>
                    <v-btn
                        color="primary"
                        @click="cancelDialog"
                        text
                    >Close</v-btn>
                </template>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>

import axios from 'axios';

import { reactive, ref, computed, watch, provide, toRefs } from '@vue/composition-api'

import StatusAssignmentStep from '@/components/it/inventory/review/StatusAssignmentStep'
import LocationStep from '@/components/it/inventory/review/LocationStep'
import ReviewStep from '@/components/it/inventory/review/ReviewStep'

export default {
    name: 'ReviewDialog',
    components: {
        StatusAssignmentStep,
        LocationStep
    },
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    setup(props, { refs, root }) {

        const store = root.$store;

        const { item } = toRefs(props);


        const initConfig = {
            loadingSubmit: false,
            showDialog: false,
            isNewReview: false,
            selectedReview: null,
            loadingDelete: false,
        };

        const config = reactive({...initConfig});
        const curStep = ref(1);

        const locStep = {
            name: 'Location',
            component: LocationStep,
            ref: 'locStep',
        };
        
        const steps = reactive([{
            name: 'Status & Assignment',
            component: StatusAssignmentStep,
            ref: 'statAssignStep',
        }, locStep, {
            name: 'Review',
            component: ReviewStep,
            ref: 'reviewStep'
        }]);

        const review = computed(() => store.getters['inventory/getPendingReview'](props.item.pk));
        const curReview = computed(() => store.getters['inventory/getCurrentReview']);
        const approverMode = computed(() => store.getters['inventory/getReviewApproverMode']);

        const prevReviews = computed(() => {
            const epReviews = store.getters['inventory/getEndpointReviews'].filter(x => x.review_num !== curReview.value.review_num);
            return [{review_num: false}, ...epReviews];
        });

        const isLatestReview = computed(() => {
            return (prevReviews.value.length === 1) || !prevReviews.value.some(x => x.review_num > curReview.value.review_num)
        })

        const newStatus = computed(() => review.value ? review.value.status : null);
        const isApproved = computed(() => !!curReview.value.approved_at && !config.isNewReview);
        const reviewNum = computed(() => {
            let num = curReview.value.review_num ? curReview.value.review_num : 1;
            if (config.isNewReview) num += 1;
            return num;
        });
        const newLocation = computed(() => !!review.value.location.newAnnotation);

        const noticeType = computed(() => {
            if (!curReview.value.approved_at) {
                return 'info';
            } else if (props.item.needs_review && props.item.needs_review.soon) {
                return 'warning';
            } else {
                return 'success';
            }
        });

        const showAnnotationKey = computed(() => {
            return (
                approverMode.value &&
                curStep.value &&
                steps[curStep.value -1].name === 'Location' &&
                review.value.location &&
                review.value.location.building.name !== 'Remote / Other'
            );
        });

        provide('isApproved', isApproved);
        provide('reviewNum', reviewNum.value);

        watch(newStatus, (val) => {
            if (val === 'Cannot be found') {
                steps.splice(1, 1);

                store.dispatch('inventory/updatePendingReview', {
                    id: props.item.pk,
                    field: 'location',
                    value: {}
                });

            } else if (steps[1].name !== 'Location') {
                steps.splice(1, 0, locStep);
            }
        });

        const nextStep = () => {
            if (refs[steps[curStep.value - 1].ref] && !refs[steps[curStep.value - 1].ref][0].validate()) return;

            curStep.value = Math.min(curStep.value + 1, steps.length)
        };

        const resetConfig = (payload=false) => {
            let newConfig = JSON.parse(JSON.stringify(initConfig));
            if (payload) newConfig = {...newConfig, ...payload};
            Object.assign(config, newConfig);
        };

        const cancelDialog = () => {
            resetConfig();
        };

        const cancelReview = async () => {
            config.loadingDelete = true;
            const response = await store.dispatch('inventory/cancelReview', {reviewId: curReview.value.pk, endpointId: props.item.pk});
            // const response = await axios.delete(`/inventory/delete_review/${curReview.value.pk}/`);
            if (response.status !== 'success') {
                store.commit('setSnack', {message: `Could not delete review. Please reach out to an administrator.`, color: 'danger', timeout: 5000});
            } else {
                await store.dispatch('inventory/loadReviews');
            }
            resetConfig();
            curStep.value = 1;
            config.loadingDelete = false;
        }
        
        const actionBtn = computed(() => {
            if (!props.item.last_review) {
                return { text: 'Needs Review' };
            } else if (approverMode.value) {
                if (!props.item.last_review.approved_at) {
                    return {
                        text: 'Review',
                        showTime: 'created_at',
                        timePrefix: 'submitted '
                    };
                } else {
                    return {
                        text: 'Approved',
                        icon: 'mdi-check-circle',
                        color: 'success',
                        showTime: 'approved_at',
                    };
                }
            } else if (!props.item.last_review.approved_at) {
                return {
                    icon: 'mdi-checkbox-blank-circle-outline',
                    showTime: 'created_at',
                    text: 'Submitted',
                    color: 'success'
                };
            } else if (props.item.needs_review.soon) {
                return {
                    icon: 'mdi-clock-outline',
                    text: 'Due Soon',
                    showNRTime: true,
                    color: 'primary'
                }
            } else if (props.item.needs_review.due) {
                return {
                    text: 'Needs Review'
                }
            } else {
                return {
                    icon: 'mdi-check-circle',
                    text: 'OK',
                    color: 'success'
                };
            }
        });

        const statusTime = computed(() => {
            if (props.item.last_review && actionBtn.value.showTime) {
                return {
                    time: props.item.last_review[actionBtn.value.showTime],
                    timeStr: `${actionBtn.value.timePrefix || ''}${root.dateDistance(props.item.last_review[actionBtn.value.showTime])} ago`
                };
            } else if (actionBtn.value.showNRTime) {
                return {
                    time: props.item.needs_review.date,
                    timeStr: root.fmtDate(props.item.needs_review.date)
                };
            } else {
                return { time: null };
            }
        });


        const chgReview = (e) => {
            if (!e.review_num) return;
            setupReview(false, false, e);
        };


        const setupNewReview = () => {
            curStep.value = 1;
            resetConfig({
                showDialog: true,
                isNewReview: true
            });
            // store.dispatch('inventory/setCurrentReview', Object.keys(curReview.value).length || prevReviews.value[0]);
        };


        const setupReview = (evt, newReview=false, existingReview=false) => {

            store.dispatch('inventory/loadEndpointReviews', {endpointId: props.item.pk});
            store.dispatch('inventory/setCurrentReview', existingReview || props.item.last_review || {});

            config.selectedReview = config.selectedReview || prevReviews.value[0];

  
            let pendReview = {
                id: props.item.pk
            };

            let pendReviewPayload = {};

            if (!props.item.last_review || newReview) {

                pendReviewPayload = {
                    status: 'No change',
                    assign: 'No change',
                    tag: 'Tag is legible',
                    peripherals: 'All OK',
                    location: {}
                };

                if (newReview) {
                    pendReview.replace = true;
                    pendReviewPayload.location = !item.value.last_review ? item.value.location : item.value.last_review.data.location;
                    setupNewReview();
                }

            } else if (existingReview) {

                pendReview.replace = true;
                pendReviewPayload = {
                    ...existingReview.data
                };

                curStep.value = steps.length;

            } else {

                pendReviewPayload = {
                    ...props.item.last_review.data
                };

                curStep.value = steps.length;
            }

            pendReview.payload = {
                ...pendReviewPayload,
                endpoint_id: props.item.pk
            };

            store.dispatch('inventory/addPendingReview', pendReview);
        };


        const submit = async () => {

            config.loadingSubmit = true;

            const response = await axios.post('/inventory/submit_inventory_review/', review.value);
            
            if (response.data.endpoint_review_id) {
                await store.dispatch('inventory/loadReviews');  // reload
                resetConfig();
            }

            config.loadingSubmit = false;
        };


        const approve = async() => {
            config.loadingSubmit = true;

            const response = await axios.post('/inventory/approve_inventory_review/', {
                review_id: props.item.last_review.pk,
                ...review.value
            });

            if (response.data.success) {
                await store.dispatch('inventory/loadReviews', {approver: true});
                config.showDialog = false;
            }

            config.loadingSubmit = false;
        };



        return {
            config,
            curStep,
            steps,
            nextStep,
            cancelDialog,
            setupReview,
            submit,
            actionBtn,
            statusTime,
            approverMode,
            approve,
            review,
            reviewNum,
            newLocation,
            prevReviews,
            chgReview,
            curReview,
            isLatestReview,
            noticeType,
            showAnnotationKey,
            cancelReview
        }

    }
};
</script>