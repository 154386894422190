<template>
    <div>
    <v-row>
        <v-col cols="6">
            <div class="overline">Status</div>
            <div class="text-h5">{{ review.status }}</div>
        </v-col>
        <template v-if="!['Cannot be found'].includes(review.status)">
            <v-col cols="6">
                <div class="overline">Assignment</div>
                <div class="text-h5">{{ review.assign }}</div>
                <div v-if="review.assign === 'Assign to Employee' && review.assignTo" class="text-h6 grey--text font-weight-light mt-n1">
                    {{ review.assignTo.name }}
                </div>
            </v-col>
            <v-col cols="6">
                <div class="overline">Tag</div>
                <div class="text-h5">{{ review.tag }}</div>
            </v-col>
            <v-col cols="6" v-if="!['Printer / MFP'].includes(item.endpoint_type)">
                <div class="overline">Peripherals</div>
                <div class="text-h5">{{ review.peripherals }}</div>
            </v-col>
            <v-col cols="12" v-if="Object.keys(review.location).length">
                <div class="overline">Location</div>
                <div class="text-h5">
                    <template v-if="review.location.building.name !== 'Remote / Other'">
                        {{ review.location.building.short_name || review.location.building.name }}-{{ review.location.floor }}-{{ review.location.room }}
                        <div class="text-h6 grey--text font-weight-light mt-n1">
                            <template v-if="review.location.newAnnotation">
                            Floor plan location updated
                            </template>
                            <template v-else>
                                No changes to location on floor plan
                            </template>
                        </div>
                    </template>
                    <template v-else>
                        Remote / Other:<br>
                        <div class="text-h6 font-weight-light">
                            <div v-text="review.location.address" />
                            <div v-if="review.location.address2" v-text="review.location.address2" />
                            {{ review.location.city }}, {{ review.location.state }} {{ review.location.zip_code }}
                        </div>
                    </template>
                </div>
            </v-col>
        </template>
        <v-col cols="12" v-show="review.notes">
            <div class="overline">Notes</div>
            <div class="text-h6 font-weight-light">{{ review.notes }}</div>
        </v-col>
    </v-row>
    <!--<pre v-text="item.last_review" />-->
    </div>
</template>
<script>
import { computed } from '@vue/composition-api';

export default {
    name: 'ReviewStep',
    props: {
        item: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    setup(props, { root }) {
        
        const store = root.$store;

        const review = computed(() => store.getters['inventory/getPendingReview'](props.item.pk));

        return {
            review
        }

    }
};
</script>